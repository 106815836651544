<template>
  <div>
    <v-form ref="form" lazy-validation>
      <v-dialog
        v-model="showForm"
        max-width="1400px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ title }}</span>
            {{
              editedItem.id > 0
                ? test_type + " test# " + editedItem.code
                : "New " + test_type
            }}
            <v-spacer></v-spacer>
            {{ "Status : " + editedItem.status }}
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="2" md="2">
                <v-autocomplete
                  :items="well_list"
                  v-model="editedItem.well_id"
                  item-value="id"
                  item-text="wellname"
                  label="Well"
                  readonly
                  dense
                  :rules="[(v) => !!v || 'Well required']"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="2" md="2">
                <v-select
                  :items="users_exe"
                  v-model="editedItem.techid"
                  :readonly="test_readonly"
                  item-value="id"
                  item-text="name"
                  label="Tested by"
                  :rules="[(v) => !!v || 'Tested by required']"
                  @change="changes++"
                  dense
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="2" md="2">
                <datepicker
                  label="Test Date"
                  :edit="!test_readonly"
                  v-model="editedItem.testdate"
                  :rules="[(v) => !!v || !isvalidating || 'Test Date required']"
                  :date_min="date_min"
                  :date_max="today"
                  :clearable="false"
                  @change="changes++"
                  :key="cs"
                ></datepicker>
              </v-col>
              <v-col
                cols="12"
                sm="2"
                md="2"
                v-if="testtype_id == 3 || testtype_id == 5 || testtype_id == 6"
              >
                <v-select
                  :items="test_pressure"
                  v-model="editedItem.tool_id"
                  item-value="id"
                  item-text="label"
                  label="Pressure"
                  dense
                  @input="changes++"
                  :rules="[(v) => !!v || !isvalidating || 'Method required']"
                >
                </v-select>
              </v-col>
              <!-- <v-col cols="12" sm="2" md="2">
                <v-select
                  :items="users_eval"
                  v-model="editedItem.interpreted_by"
                  item-value="id"
                  item-text="name"
                  label="Evaluation by"
                  dense
                  readonly
                  disabled
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="2" md="2">
                <datepicker
                  label="Evaluation Date"
                  :edit="false"
                  v-model="editedItem.interpreted_at"
                  :date_min="editedItem.testdate"
                  :date_max="today"
                  disabled
                  :clearable="false"
                  :key="cs"
                ></datepicker>
              </v-col> -->
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  outlined
                  dense
                  :readonly="test_readonly"
                  v-model="editedItem.editcomment"
                  label="Comment"
                  row-height="30"
                  rows="2"
                  @input="changes++"
                  variant="outlined"
                  auto-grow
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row
              dense
              v-if="testtype_id == 7 && initial_test == 0 && editedItem.id > 0"
            >
              <v-col cols="12" sm="2" md="2">
                <v-text-field
                  autocomplete="off"
                  type="number"
                  outlined
                  dense
                  v-model.number="Hydraulic_oil_return"
                  :label="'Hydraulic oil return (' + pressure_unit + ')'"
                  hide-spin-buttons
                  @input="change++"
                  :readonly="test_readonly"
                  z
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2">
                <v-text-field
                  autocomplete="off"
                  type="number"
                  outlined
                  dense
                  v-model.number="opening_time"
                  :label="'Opening time (Mn)'"
                  hide-spin-buttons
                  @input="change++"
                  :readonly="test_readonly"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2">
                <v-text-field
                  autocomplete="off"
                  type="number"
                  outlined
                  dense
                  v-model.number="opening_pressure"
                  :label="'Opening pressure (' + pressure_unit + ')'"
                  hide-spin-buttons
                  @input="change++"
                  :readonly="test_readonly"
                ></v-text-field>
              </v-col>
                 <v-col cols="12" sm="2" md="2">
                 <v-text-field
                    autocomplete="off"
                    type="number"
                    outlined
                    dense
                    v-model="dhsv_depth"
                    :label="'Depth (' + depth_unit + ')'"
                    hide-spin-buttons
                    @input="change++"
                  ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense v-if="testtype_id == 8 && editedItem.id > 0">
              <v-col cols="12" sm="4" md="4">
                <v-select
                  :items="welltubulars" 
                  v-model="editedItem.welltubular_id"
                  item-value="id"
                  item-text="annulus"
                  label="Annulus"
                  :rules="[(v) => !!v || 'Annulus required']"
                  outlined
                  dense
                  :disabled="!editedItem.well_id"
                  @change="changes++"
                  chips
                  :readonly="test_readonly"
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  autocomplete="off"
                  type="number"
                  outlined
                  dense
                  v-model.number="editedItem.leak_depth"
                  :label="'Depth'"
                  hide-spin-buttons
                  @input="changes++"
                  :readonly="test_readonly"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-tabs
        
              v-model="tab0"
              class="my-tabs"
              align-tabs="left"
              v-if="founds_list.length > 0 && editedItem.id > 0"
              :key="keyAll"
            >
              <v-tab
                key="1"
                href="#1"
                v-if="
                  tab_label &&
                  (testtype_id != 7 || (testtype_id == 7 && initial_test > 0))
                "
              >
                {{ tab_label }}
              </v-tab>
              <v-tab key="2" href="#2"> As found report </v-tab>
              <v-tab key="3" href="#3"> As left report </v-tab>
              <v-tab
                key="4"
                href="#4"
                v-if="
                  testtype_id != 1 &&
                  (editedItem.statut_id == 6 || editedItem.statut_id == 7)
                "
              >
                Addtional tests
              </v-tab>
              <v-tab key="5" href="#5"> Event Log </v-tab>
              <v-tab
                key="6"
                href="#6"
                v-if="editedItem.statut_id == 6 || editedItem.statut_id == 7"
              >
                Failures
              </v-tab>
              <v-tab key="7" href="#7">
                Attachments
                <v-icon> mdi-paperclip </v-icon>
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab0">
              <v-tab-item :value="'1'">
                <br />

                <t_apm
                  v-if="testtype_id == 1"
                  ref="t1"
                  :apms="apms_list"
                  :editedItem="editedItem"
                  :test_readonly="test_readonly"
                  :failures_list="failures_list"
                  @changes="apm_change"
                ></t_apm>

                <br />
                <t_xtree
                  v-if="testtype_id == 3"
                  ref="t3"
                  :xtrees="xtrees_list"
                  :editedItem="editedItem"
                  :test_readonly="test_readonly"
                  :failures_list="failures_list"
                  @changes="wh_change"
                ></t_xtree> 

                <t_bo
                  v-if="testtype_id == 4"
                  ref="t4"
                  :test_bo="test_bo_list"
                  :editedItem="editedItem"
                  :test_readonly="test_readonly"
                  :failures_list="failures_list"
                  @changes="bo_change"
                ></t_bo>

                <br />

                <t_dhsv
                  v-if="testtype_id == 7"
                  ref="t7"
                  :testdhsv="testdhsv"
                  :editedItem="editedItem"
                  :test_readonly="test_readonly"
                  :failures_list="failures_list"
                  @changes="dhsv_change"
                ></t_dhsv>

                <br />

                <t_wh
                  v-if="testtype_id == 5"
                  ref="t5"
                  :xtrees="xtrees_list"
                  :editedItem="editedItem"
                  :test_readonly="test_readonly"
                  :failures_list="failures_list"
                  @changes="wh_change"
                ></t_wh>

                <br />

                <t_downhole
                  v-if="testtype_id == 6"
                  ref="t6"
                  :editedItem="editedItem"
                  :downs="downs_list"
                  :test_readonly="test_readonly"
                  :failures_list="failures_list"
                  @changes="down_change"
                ></t_downhole>
              </v-tab-item>
              <v-tab-item :value="'2'">
                <WellFound
                  :founds="founds_list"
                  :stap="'A'"
                  :t_readonly="test_readonly"
                  @changes="changes++"
                ></WellFound>
              </v-tab-item>
              <v-tab-item :value="'3'">
                <WellFound
                  :founds="founds_list"
                  :stap="'B'"
                  :t_readonly="test_readonly"
                  @changes="changes++"
                ></WellFound>
              </v-tab-item>
              <v-tab-item :value="'4'">
                <br />
                <TestAdditional
                  :tests="tests"
                  :test_id="parseInt(editedItem.id)"
                  :well_id="parseInt(editedItem.well_id)"
                  :readonly="editedItem.statut_id != 6 || !valider"
                  :teststypes_list="teststypes_list"
                ></TestAdditional>
              </v-tab-item>
              <v-tab-item :value="'5'">
                <listitems
                  :list="events"
                  :headers="events_header"
                  :toolbar="false"
                  :key="kev"
                >
                </listitems>
              </v-tab-item>
              <v-tab-item :value="'6'">
                <listitems
                  :list="
                    failures_list
                      ? failures_list.filter(
                          (x) =>
                            x.welltest_id == editedItem.id ||
                            (x.cmp_code == '0' &&
                              x.discription.match(test_label))
                        )
                      : null
                  "
                  :headers="failures_headers"
                  :toolbar="false"
                  :key="kfail"
                  @col_btn1_click="FailureOpen"
                >
                </listitems>
              </v-tab-item>
              <v-tab-item :value="'7'">
                <filelist
                  :item="editedItem"
                  :editer="true"
                  :doc_type="parseInt(editedItem.testtype_id)"
                  :isform="false"
                  :auth="'01007'"
                  :well_id="parseInt(editedItem.well_id)"
                >
                </filelist>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <Test_Accept
              :test="editedItem"
              :founds="founds_list"
              :key="ta"
              @close="close"
              @test_save="test_save"
              @status_change="status_change"
              :changes="changes"
              :testtype_id="parseInt(editedItem.testtype_id)"
              @validate_test="validate_test"
              @accept_test="accept_test"
              :save_disable="save_disable"
            ></Test_Accept>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <givenform
      :dialog="givendialog"
      :wellstypes="wellstypes"
      :welltype="editedItem.welltype_id"
      :failure="failure"
      :given="given"
      :given_action="given_action_list"
      @close_given="close_given"
      :key="kga"
    ></givenform>
  </div>
</template>

<script>
function incrementDate(dateInput, increment) {
  var dateFormatTotime = new Date(dateInput);
  var increasedDate = new Date(
    dateFormatTotime.getTime() + increment * 86400000
  );
  return increasedDate;
}
import TESTFOUND from "../graphql/WellTest/APM/TestFound.gql";
import SAVE_TEST from "../graphql/WellTest/APM/SaveTest.gql";
import GIVEN_ACTION from "../graphql/Tests/GIVEN_ACTION.gql";
export default {
  components: {
    datepicker: () => import("../components/DatePicker.vue"),
    WellFound: () => import("../components/Well_Found.vue"),
    filelist: () => import("./FileList.vue"),
    listitems: () => import("../components/ListItems.vue"),
    Test_Accept: () => import("../components/Test_Accept.vue"),
    TestAdditional: () => import("../components/Test_Additional.vue"),
    t_apm: () => import("../components/T_APM.vue"),
    t_bo: () => import("../components/T_BO.vue"),
    t_dhsv: () => import("../components/T_DHSV.vue"),
    t_downhole: () => import("../components/T_Downhole.vue"),
    t_wh: () => import("../components/T_WellHead.vue"),
    t_xtree: () => import("../components/T_Xtree.vue"),
    givenform: () => import("../components/GivenAction.vue"),
  },
  props: {
    showForm: Boolean,
    item: Object,
    well: Object,
    well_list: Array,
    tests_list: Array,
    founds: Array,
    apms: Array,
    downs: Array,
    test_bo: Array,
    xtrees: Array,
    failures: Array,
    barriers: Array,
    all_failures: Array,
    testdhsv: Object,
    user_list: Array,
    tab_label: String,
    testtype_id: Number,
    teststypes_list: Array,
    tubulars_list: Array,
    editer: Boolean,
    valider: Boolean,
  },
  data() {
    return {
      wellstypes: [],
      given_action_list: [],
      given: {},
      failure: {},
      givendialog: false,
      kga: 6000,
      date_min: null,
      Hydraulic_oil_return: 0,
      opening_time: 0,
      opening_pressure: 0,
      dhsv_depth:0,
      initial_test: 0,
      tests: [],
      events: [],
      test_type: "",
      founds_list: [],
      apms_list: [],
      pressure_unit: "",
      test_pressure: [
        { id: "1", label: "Pressure test with pump" },
        { id: "2", label: "Leak off test (Sting-in)" },
      ],
      statut_id: 1,
      save_disable: false,
      failures_list: [],
      keyAll: 10000,
      divs: 50000,
      cs: 100,
      fdocl: 1000,
      st: 2550,
      tab0: 1,
      ta: 150,
      kev: 160,
      kfail: 60000,
      btn_click: false,
      test_readonly: false,
      editedItem: {},
      changes: 1,
      change: 0,
      title: "",
      annuli: [],
      downs_list: [],
      test_bo_list: [],
      xtrees_list: [],
      test_ok: false,
      test_dhsv: {},
      isvalidating: false,
      events_header: [
        {
          value: "id",
          selected: false,
        },
        {
          text: "Date",
          value: "event_date",
          selected: true,
        },
        {
          text: "Operation",
          value: "statut",
          selected: true,
        },
        {
          text: "BY",
          value: "operator",
          selected: true,
        },
        {
          text: "Comment",
          value: "comment",
          selected: true,
        },
      ],
      failures_headers: [
        {
          slot: "col_btn1",
          selected: true,
          icon: "pen-cil",
          color: "indigo",
          width: "10",
          valeur: 0,
          sortable: false,
        },
        {
          text: "Item",
          value: "issue",
          selected: false,
        },
        {
          text: "Element",
          value: "component",
          selected: true,
        },
        {
          text: "Description",
          value: "discription",
          selected: true,
        },

        {
          text: "Failure rate(%)",
          value: "failure_taux",
          slot: "progress",
          align: "center",
          width: 140,
          selected: true,
        },
        {
          text: "Risk Ranking",
          value: "risk",
          slot: "chip",
          color: "color",
          selected: true,
        },
        {
          text: "Measure Action",
          value: "action",
          selected: false,
        },
        {
          text: "Due Date",
          value: "repair_date",
          selected: true,
        },
        {
          text: "Code Action",
          value: "code_given_action",
          selected: true,
        },
        {
          text: "Given action",
          value: "given_action",
          selected: true,
        },
      ],
      anl_count: 0,
      test_label: "",
    };
  },
  created() {
    this.$nextTick(() => {
      this.changes = 0;
    });
  },
  mounted() {
    if (this.testtype_id == 3) this.test_label = "Xtree";
    else if (this.testtype_id == 5) this.test_label = "Head";
    else this.test_label = "Down";
    let i = this.teststypes_list.findIndex((elm) => elm.id == this.testtype_id);
    if (i >= 0) {
      this.test_type = this.teststypes_list[i].description;
    }
    if (this.item) {
      this.cs++;

      if (this.item.id > 0) {
        this.editedItem = Object.assign({}, this.item);
        this.founds_list = Object.assign([], this.founds);
        this.failures_list = this.failures;
        this.anl_count = Math.max(...this.downs.map((item) => item.csg_order));
        this.apms_list = Object.assign([], this.apms);
        this.downs_list = Object.assign([], this.downs);
        this.xtrees_list = Object.assign([], this.xtrees);
        this.test_bo_list = Object.assign([], this.test_bo);
        this.tests = this.item.tests;
        this.events = this.item.events;
        if (this.editedItem.wellstest_tubulars.length > 0) {
          this.editedItem.welltubular_id =
            this.editedItem.wellstest_tubulars[0].well_tubular_id;
        }
        if (this.testtype_id == 7) this.get_dhsv_props();
      } else {
        this.editedItem = Object.assign({}, { id: -1, statut_id: 4 });
        this.editedItem.interpreted_by = this.$store.state.me.id;
        this.editedItem.interpreted_at = this.$store.state.today;
        this.date_min = this.drillingdate;
        if (this.tests_list)
          if (this.tests_list.length > 0) {
            let d = new Date(this.tests_list[0].testdate);
            this.date_min = incrementDate(d, 1).toISOString().slice(0, 10);
          }
        this.cs++;
        if (this.well_list.length == 1)
          this.editedItem.well_id = this.well_list[0].id;
        this.founds_list = [];
        this.failures_list = [];
        this.apms_list = [];
        this.downs_list = [];
        this.xtrees_list = [];
        this.test_bo_list = [];
        this.tests = [];
        this.events = [];
      }
      this.get_status();
      this.ta++;
    }
    this.test_dhsv = this.testdhsv;
  },
  computed: {
    welltubulars() {
      let l = [];

      if (this.tubulars_list) {
        l = this.tubulars_list.filter(
          (elm) => elm.well_id == this.editedItem.well_id
        );
      }

      l.sort(this.sortBy("csg_order"));
      return l;
    },

    drillingdate() {
      return this.well ? this.well.drillingdate : this.$store.state.today;
    },
    today() {
      return this.$store.state.today;
    },
    users_exe() {
      let l = [];
      l = this.user_list.filter((elm) => elm.autorisations.includes("06003"));
      return l;
    },
    users_eval() {
      let l = [];
      l = this.user_list.filter(
        (elm) =>
          elm.autorisations.includes("07003") || this.$store.state.isadmin
      );
      return l;
    },
  },
  watch: {},
  methods: {
    close_given(item) {
      if (item) {
        this.getdata(parseInt(this.selected_well.id));
      }
      this.givendialog = false;
    },
    async getGivenAction() {
      let r = await this.$requette(
        GIVEN_ACTION,
        {
          failures: this.failure.barrier,
        },
        "no-cache"
      );
      if (r.ok) {
        this.wellstypes = r.data.wellstypes;
        this.given_action_list = r.data.given_action;
        if (r.data.getGivenAction[0]) this.given = r.data.getGivenAction[0];
        else
          this.given = {
            id: null,
            failures: this.failure.item,
            t_1: -1,
            t_2: -1,
            t_3: -1,
            t_4: -1,
            t_5: -1,
            t_6: -1,
            t_7: -1,
            t_8: -1,
          };
      }
    },
    async FailureOpen(item) {
      this.failure = item;
      await this.getGivenAction();
      this.kga++;
      this.givendialog = true;
    },
    sortBy() {
      return function (a, b) {
        if (a < b) return 1;
        if (a > b) return -1;
        return 0;
      };
    },
    get_dhsv_props() {
      if (this.testdhsv.dhsv != null) {
        let A = this.testdhsv.dhsv.split(";");
        if (A.length > 0) {
          this.Hydraulic_oil_return = A[0];
          this.opening_time = A[1];
          this.opening_pressure = A[2];
          this.dhsv_depth = A[3];
          if (
            this.Hydraulic_oil_return > 0 &&
            this.opening_time > 0 &&
            this.opening_pressure > 0
          )
            this.initial_test = 1;
        }
      }
    },
    apm_change(apms, failures_list) {
      this.apms_list = apms;
      this.failures_list = failures_list;
      this.changes++;
    },
    bo_change(test_bo_list, failures_list) {
      this.test_bo_list = test_bo_list;
      this.failures_list = failures_list;

      this.changes++;
    },
    dhsv_change(testdhsv, failures_list) {
      this.test_dhsv = testdhsv;
      this.failures_list = failures_list;
      this.changes++;
    },
    down_change(downs_list, failures_list) {
      this.downs_list = downs_list;
      this.failures_list = failures_list;
      this.changes++;
    },
    wh_change(xtrees_list, failures_list) {
      this.xtrees_list = xtrees_list;
      this.failures_list = failures_list;
      this.changes++;
    },
    close() {
      this.$emit("close");
    },
    get_status() {
      this.test_readonly =
        (this.editedItem.statut_id != 4 &&
          this.editedItem.statut_id != 5 &&
          this.editedItem.statut_id != 8) ||
        !this.editer;
      if (this.editedItem.statut_id == 4) this.editedItem.status = "Attributed";
      if (this.editedItem.statut_id == 5)
        this.editedItem.status = "In Progress";
      if (this.editedItem.statut_id == 6) this.editedItem.status = "Completed";
      if (this.editedItem.statut_id == 7) this.editedItem.status = "Accepted";
      if (this.editedItem.statut_id == 8) this.editedItem.status = "Rejected";
    },
    status_change(status) {
      this.editedItem.statut_id = status;
      this.get_status();
      this.$emit("update", this.editedItem);
      this.get_data();
    },

    validate_test(callback) {
      this.isvalidating = true;
      // Perform your validation logic here

      this.tab0 = 1;
      this.test_ok = this.$refs.form.validate();
      if (this.test_ok && this.testtype_id != 8)
        this.test_ok = this.$refs["t" + this.testtype_id].validate();
      // Send the result back to the child via the callback
      callback(this.test_ok);
    },
    accept_test(callback) {
      callback(true);
    },
    async test_save() {
      if (this.$refs.form.validate()) { 
        this.save_disable = true;
        let vn = [];
        let vfounds = [];
        let dhsv;
        let vxtrees = [];
        let downs = [];
        let vapms = [];
        let vfailures = [];
        let vbo = [];
        let dhsv_values = null;
        if (
          this.testtype_id == 7 &&
          this.editedItem.id &&
          this.editedItem.id > 0
        ) {
          if (this.initial_test == 0) {
            dhsv_values =
              String(this.Hydraulic_oil_return) +
              ";" +
              String(this.opening_time) +
              ";" +
              String(this.opening_pressure);
                ";" +
              String(this.dhsv_depth);
          }
        }
        if (this.founds_list)
          this.founds_list.forEach((f) =>
            vfounds.push({ id: f.id, asfound: f.asfound, asleft: f.asleft })
          );
        if (this.apms_list)
          this.apms_list.forEach((f) =>
            vapms.push({
              id: f.id,
              well_tubular_id: f.well_tubular_id,
              value: parseFloat(f.value),
            })
          );
        if (this.xtrees_list)
          this.xtrees_list.forEach((f) => {
            vxtrees.push({
              id: f.id,
              operat: f.operat,
              c_turns: parseInt(f.c_turns),
              o_turns: parseInt(f.o_turns),
              fail_open: f.fail_open ? 1 : 0,
              fail_close: f.fail_close ? 1 : 0,
              i_pressure: parseFloat(f.i_pressure),
              f_pressure: parseFloat(f.f_pressure),
              coment: f.coment,
              element: f.valelement ? f.valelement.toString() : null,
            });
          });
        if (this.downs_list)
          this.downs_list.forEach((f) =>
            downs.push({
              id: f.id,
              time: parseInt(f.time),
              p_volume: parseFloat(f.p_volume),
              plug_depth: parseFloat(f.plug_depth),
              drop_pressure: parseFloat(f.drop_pressure),
              i_pressure: parseFloat(f.i_pressure),
              f_pressure: parseFloat(f.f_pressure),
              anl_a: parseFloat(f.anl_a),
              anl_b: parseFloat(f.anl_b),
              anl_c: parseFloat(f.anl_c),
              anl_d: parseFloat(f.anl_d),
              anl_e: parseFloat(f.anl_e),
              cmp_code: f.cmp_code,
              well_tubular_id: f.well_tubular_id,
            })
          );
        if (this.test_dhsv.id && this.test_dhsv.id > 0)
          dhsv = {
            id: parseInt(this.test_dhsv.id),
            o_time: parseFloat(this.test_dhsv.o_time),
            o_pressure: parseFloat(this.test_dhsv.o_pressure),
            i_pressure: parseFloat(this.test_dhsv.i_pressure),
            f_pressure: parseFloat(this.test_dhsv.f_pressure),
            time: parseInt(this.test_dhsv.time),
            hyd_oil: parseFloat(this.test_dhsv.hyd_oil),
            cmp_code: this.test_dhsv.cmp_code,
          };
        if (this.test_bo_list)
          this.test_bo_list.forEach((f) =>
            vbo.push({
              id: f.id,
              i_pressure: parseFloat(f.i_pressure),
              f_pressure_12hrs: parseFloat(f.f_pressure_12hrs),
              f_pressure_24hrs: parseFloat(f.f_pressure_24hrs),
              f_pressure: parseFloat(f.f_pressure),
              fluid_id: parseInt(f.fluid_id),
              time: parseInt(f.time),
              c_flow: f.c_flow,
              remark: f.remark,
            })
          );
        if (this.failures_list)
          this.failures_list
            .filter((a) => a.welltest_id == this.editedItem.id)
            .forEach((f) =>
              vfailures.push({
                id: f.id,
                failure_id: parseInt(f.failure_id),
                cmp_code: String(f.cmp_code),
                cmp_id: f.cmp_id,
                well_tubular_id: f.well_tubular_id,
                failure_taux: f.failure_taux,
                welltest_id: parseInt(this.editedItem.id),
                well_id: parseInt(this.editedItem.well_id),
                statut_id: parseInt(this.statut_id),
                g_id: parseInt(f.g_id ? f.g_id : -1),
                multiple: f.multiple,
                barrier: f.barrier,
              })
            );

        this.editedItem.testtype_id = this.testtype_id;
        this.editedItem.status = "Attributed";
        if (this.editedItem.id < 0) this.editedItem.id = null;
        vn.push({
          id: this.editedItem.id,
          well_id: this.editedItem.well_id,
          testtype_id: this.editedItem.testtype_id,
          techid: this.editedItem.techid,
          editcomment: this.editedItem.editcomment,
          testdate: this.editedItem.testdate,
          interpreted_by: this.editedItem.interpreted_by,
          interpreted_at: this.editedItem.interpreted_at,
          tool_id: this.editedItem.tool_id,
          leak_depth: this.editedItem.leak_depth,
          welltubular_id: this.editedItem.welltubular_id,
          statut_id: this.editedItem.id < 0 ? 4 : 5,
        });

        await this.$apollo
          .query({
            query: SAVE_TEST,
            variables: {
              test: vn,
              founds: vfounds,
              apms: vapms.length > 0 ? vapms : null,
              dhsv: dhsv,
              valves: vxtrees.length > 0 ? vxtrees : null,
              downhead: downs.length > 0 ? downs : null,
              testbo: vbo.length > 0 ? vbo : null,
              failures: vfailures.length > 0 ? vfailures : null,
              test_initial: dhsv_values,
              welltests_tubulars: this.editedItem.welltubular_id
                ? [this.editedItem.welltubular_id]
                : null,
            },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            this.refresh(data);
            this.changes = 1;
          })
          .catch(() => {});
        this.save_disable = false;
      }
    },
    async refresh(data) {
      this.editedItem.code = data.data.SaveTest.code;
      this.editedItem.id = data.data.SaveTest.id;
      this.$emit("update", this.editedItem);
      this.events = this.item.events;
      this.get_data();
    },

    async get_data() {
      let r = await this.$requette(
        TESTFOUND,
        {
          TestID: parseInt(this.editedItem.id),
          WellID: parseInt(this.editedItem.well_id),
        },
        "no-cache"
      );
      if (r.ok) {
        this.founds_list = r.data.test_well_found;
        this.apms_list = r.data.test_well_apm;
        this.test_bo_list = r.data.test_well_bo;
        this.xtrees_list = r.data.test_well_valves;
        this.downs_list = r.data.test_downhead;
        this.failures_list = r.data.test_failures_single;

        if (this.testtype_id == 7) {
          if (r.data.test_dhsv.length > 0) this.test_dhsv = r.data.test_dhsv[0];
          this.get_dhsv_props();
        }
        this.kev++;
        this.ta++;
      }
    },
  },
};
</script>
<style>
.rowh0 {
  width: 100% !important;
  height: 45px !important;
}
</style>
